<template>
  <el-select
    v-model="memos"
    :remote-method="searchMemo"
    :remote="true"
    :loading="loading"
    clearable
    filterable
    @change="updateParent">
    <el-option v-for="mem in dmemos" :key="mem.id" :value="mem.id" :label="mem.subject">{{ mem.subject }}</el-option>
  </el-select>
</template>

<script>
import { DesignationMemo } from '@/api/records/designation/designation_memo'

export default {
  name: 'SelectMemo',
  props: {
    post: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      defaultHead: {
        id: 0,
        fullname: 'No Memo yet'
      },
      dmemos: [],
      memos: this.post
    }
  },
  watch: {
    post: function(item) {
      this.dmemos = item || null
      this.memos = item ? [item] : []
    }
  },
  created() {
    if (this.post !== null) {
      this.dmemos = [this.post]
      this.memos = this.post
    }
    this.dmemos = this.dmemos.filter(function(element) {
      return element !== undefined || element !== null
    })
  },
  methods: {
    updateParent(id) {
      this.$emit('setMemo', id)
      console.log(id)
    },
    searchMemo(query) {
      if (!query) query = ''
      if (query.length > 2) {
        this.loading = true
        setTimeout(() => {
          DesignationMemo({ term: query }, 'get').then(res => {
            this.dmemos = res.data.designation_memo
            this.loading = false
          }).catch(() => {})
        }, 100)
      } else {
        this.dmemos = []
      }
    }
  }
}

</script>


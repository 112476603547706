<template>
  <div style="margin: 20px;">
    <h3>{{ header_name }}</h3>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card shadow="never">
          <el-row>
            <el-form :inline="true" @submit.native.prevent="search()">
              <el-form-item>
                Filter:
              </el-form-item>
              <el-form-item>
                <el-input v-model="search_name" size="medium" placeholder="Personnel" clearable style="width: 250px;" />
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="search_dept_id"
                  size="medium"
                  placeholder="Office"
                  filterable
                  clearable
                  style="width: 250px;">
                  <el-option
                    v-for="department in departments"
                    :key="department.id"
                    :value="department.id"
                    :label="department.acronym">
                    {{ department.fullname + ' (' + department.acronym + ')' }}
                    <span v-if="!department.head_id" style="color: #F56C6C; font-weight: bold;">No Head</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Search" placement="top">
                  <el-button :icon="icon_search" type="primary" size="medium" @click.native.prevent="search()" />
                </el-tooltip>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Refresh" placement="top">
                  <el-button :icon="icon_refresh" type="info" size="medium" @click.native.prevent="refresh()" />
                </el-tooltip>
              </el-form-item>
            </el-form>
          </el-row>
          <el-row>
            <el-table
              v-loading="get_all_loading"
              ref="files_201_personnel_table"
              :data="files_201_personnel"
              size="mini"
              style="width: 100%; font-size: 14px;">
              <el-table-column label="Personnel" width="">
                <template slot-scope="scope">
                  <span style="word-break: break-word;">{{ scope.row.fullname }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Office" width="">
                <template slot-scope="prop">
                  <el-tooltip
                    v-if="prop.row.depart"
                    :key="'iftpac0' + prop.row.id"
                    :content="prop.row.depart.fullname"
                    effect="dark"
                    placement="right">
                    <span style="word-break: break-word; cursor: default;">{{ prop.row.depart.acronym }}</span>
                  </el-tooltip>
                  <span v-else style="color: #F56C6C; font-weight: bold;">No Office</span>
                </template>
              </el-table-column>
              <el-table-column label="Operation" width="110" align="center">
                <template slot-scope="prop">
                  <div style="margin-top: 8px">
                    <div v-if="prop.row.id !== personnel_id">
                      <el-tooltip
                        v-if="prop.row.files"
                        :key="'iftpac1' + prop.row.id"
                        effect="dark"
                        content="View Academic 201 Files"
                        placement="top">
                        <el-badge
                          :key="'ifbeac2' + prop.row.id"
                          :value="prop.row.files"
                          class="item"
                          style="margin-right: 30px;">
                          <el-button
                            :key="'ifbnac3' + prop.row.id"
                            type="primary"
                            size="mini"
                            @click="viewContentList(prop.row)">
                            <i class="fa fa-list" />
                          </el-button>
                        </el-badge>
                      </el-tooltip>
                      <el-tooltip
                        v-else
                        :key="'elsetpac4' + prop.row.id"
                        effect="dark"
                        content="View Academic 201 Files"
                        placement="top">
                        <el-button
                          :key="'elsebnac5' + prop.row.id"
                          type="primary"
                          size="mini"
                          style="margin-right: 10px;"
                          @click="viewContentList(prop.row)">
                          <i class="fa fa-list" />
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div v-else>
                      <el-tooltip
                        :key="'elsetpac6' + prop.row.id"
                        effect="dark"
                        content="Close Academic 201 Files"
                        placement="top">
                        <el-button
                          :key="'elsebnac7' + prop.row.id"
                          type="danger"
                          size="mini"
                          icon="el-icon-close"
                          style="margin-right: 10px;"
                          @click="closeContentFiles()"
                        />
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" style="margin-top: 10px;">
              <el-pagination
                :page-size="meta.per_page"
                :pager-count="5"
                :total="meta.total"
                :current-page.sync="meta.page"
                layout="total, prev, pager, next"
                @current-change="getAll()"
              />
            </div>
          </el-row>
        </el-card>
      </el-col>
      <el-col v-if="view_cl" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card shadow="never">
          <el-row>
            <h3>{{ header_name }} -> <span style="color: #28a73a;">{{ personnel_selected.fullname }}</span></h3>
          </el-row>
          <el-row>
            <el-form :inline="true" @submit.native.prevent="searchList()">
              <el-form-item>
                <el-input
                  v-model="search_list"
                  size="medium"
                  placeholder="Search List"
                  clearable
                  style="width: 250px;"
                  @keyup.enter.native="searchList()"
                />
              </el-form-item>
            </el-form>
          </el-row>
          <el-row>
            <el-table
              v-loading="get_cl_loading"
              ref="cl_table"
              :data="content_list_count"
              size="mini"
              style="width: 100%; font-size: 14px;">
              <el-table-column label="List" width="">
                <template slot-scope="prop">
                  <span style="word-break: break-word;">{{ prop.row.title }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Operation" width="110" align="center">
                <template slot-scope="prop">
                  <div style="margin-top: 8px;">
                    <el-tooltip
                      v-if="prop.row.count > 0"
                      :key="'iftpac7' + prop.row.id"
                      class="item"
                      effect="dark"
                      content="View Files"
                      placement="top">
                      <el-badge
                        :key="'ifbeac8' + prop.row.id"
                        :value="prop.row.count"
                        style="margin-right: 30px;">
                        <el-button
                          :key="'ifbnac9' + prop.row.id"
                          type="primary"
                          size="mini"
                          @click="viewFiles(prop.row)">
                          <i class="fa fa-list" />
                        </el-button>
                      </el-badge>
                    </el-tooltip>
                    <el-tooltip
                      v-else
                      :key="'elsetpac10' + prop.row.id"
                      class="item"
                      effect="dark"
                      content="New File"
                      placement="top">
                      <el-button
                        :key="'elsebnac11' + prop.row.id"
                        type="info"
                        size="mini"
                        icon="el-icon-document-add"
                        style="margin-right: 10px;"
                        @click="newFile(prop.row, false)"
                      />
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" style="margin-top: 10px;">
              <el-pagination
                :page-size="meta_cl.per_page"
                :pager-count="5"
                :total="meta_cl.total"
                :current-page.sync="meta_cl.page"
                layout="total, prev, pager, next"
                @current-change="getCLCount()"
              />
            </div>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="view_files"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50%"
      top="5vh">
      <div style="margin: -50px 0;">
        <h3>{{ personnel_selected.fullname }}</h3>
        <h3>
          <span style="font-style: italic; text-decoration: underline;">
            {{ content_selected.title }}
          </span>
          <a type="text" style="font-size: 14px; color: green;" @click="newFile(null, true)">
            New <i class="fas fa-plus-square"/>
          </a>
        </h3>
        <el-divider />
        <el-row v-if="content_id !== 7">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-date-picker
              v-model="search_year"
              type="year"
              size="medium"
              placeholder="Pick a year"
              style="width: 150px;"
              clearable
              @change="searchFile()"
            />
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
            <el-checkbox v-model="naf">No attached file</el-checkbox>
          </el-col>
        </el-row>
        <br>
        <el-row>
          <el-table
            v-loading="get_f_loading"
            ref="files_201_table"
            :data="files"
            size="mini"
            style="width: 100%; font-size: 14px;"
            highlight-current-row>
            <el-table-column v-if="content_id !== 7" :key="'tn1' + content_id" label="Date Created" width="">
              <template slot-scope="prop">
                {{ getFormattedDate(prop.row.date_created, 'MMMM D, YYYY') }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="content_id === 1 || content_id === 2 || content_id === 5"
              :key="'tn2' + content_id"
              label="Position">
              <template slot-scope="prop">
                <span v-if="prop.row.position_id">{{ prop.row.position.name }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="content_id !== 7" :key="'tn3' + content_id" width="" align="center">
              <template slot-scope="prop">
                <div v-if="prop.row.path" style="word-break: break-word">
                  <el-tag
                    type="success"
                    effect="plain"
                    size="medium"
                    style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">
                    File Uploaded
                  </el-tag>
                </div>
                <div v-else style="word-break: break-word">
                  <el-tag type="danger" effect="plain" size="medium" style="width: 120px;">
                    No Attached File
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="content_id === 7" :key="'tn4' + content_id" label="Memorandum">
              <template slot-scope="prop">
                <span v-if="prop.row.memo_id" style="word-break: keep-all;">{{ prop.row.memo.subject }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="content_id === 7" :key="'tn5' + content_id" width="" align="center">
              <template slot-scope="prop">
                <div v-if="prop.row.memo_id && prop.row.memo.path" style="word-break: break-word">
                  <el-tag
                    type="success"
                    effect="plain"
                    size="medium"
                    style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">
                    File Uploaded
                  </el-tag>
                </div>
                <div v-else style="word-break: break-word">
                  <el-tag type="danger" effect="plain" size="medium" style="width: 120px;">
                    No Attached File
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column :key="'tn6' + content_id" label="Operation (s)" width="150" align="right">
              <template slot-scope="prop">
                <el-button-group>
                  <el-tooltip
                    v-if="prop.row.memo_id && content_id === 7"
                    :key="'iftpac12' + prop.row.id"
                    content="View Attached File MEMO"
                    placement="top">
                    <el-button
                      v-if="prop.row.memo.path"
                      :key="'ifbnac13' + prop.row.id"
                      type="primary"
                      icon="el-icon-document"
                      size="mini"
                      style="margin-right: 10px;"
                      @click="viewAttachedFile(prop.row.memo.path, 'memo')"
                    />
                  </el-tooltip>
                  <el-tooltip
                    v-if="prop.row.path && content_id !== 7"
                    :key="'iftpac14' + prop.row.id"
                    content="View Attached File"
                    placement="top">
                    <el-button
                      v-if="prop.row.path"
                      :key="'ifbnac15' + prop.row.id"
                      type="primary"
                      icon="el-icon-document"
                      size="mini"
                      style="margin-right: 10px;"
                      @click="viewAttachedFile(prop.row.path, 'files201')"
                    />
                  </el-tooltip>
                  <el-tooltip :key="'tpmac16' + prop.row.id" content="Edit" placement="top">
                    <el-button
                      :key="'bnac17' + prop.row.id"
                      type="primary"
                      icon="el-icon-edit"
                      size="mini"
                      style="margin-right: 10px;"
                      @click="editFile(prop.row)"
                    />
                  </el-tooltip>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <div align="center" style="margin-top: 10px;">
            <el-pagination
              :page-size="meta_f.per_page"
              :pager-count="5"
              :total="meta_f.total"
              :current-page.sync="meta_f.page"
              layout="total, prev, pager, next"
              @current-change="getFiles()"
            />
          </div>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="medium" @click="closeFiles()">Cancel</el-button>
      </span>
      <el-dialog
        :visible.sync="view_attached_form"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeAttachedFile"
        :append-to-body="true"
        width="60%"
        top="5vh">
        <div>
          <embed :key="view_attached_file" :src="view_attached_file" style="width: 100%; height: 700px;">
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog
      :visible.sync="ae_form"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="append_body"
      width="25%"
      top="5vh">
      <el-row style="margin: -50px 0;">
        <el-form ref="selected" :model="selected" :rules="rules">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <h3>{{ personnel_selected.fullname }}</h3>
              <h3>
                {{ ae_title }}
                <span style="font-style: italic; text-decoration: underline; color: #0a8700;">
                  {{ content_selected.title }}
                </span>
              </h3>
              <el-divider />
              <p>
                <strong>Note:</strong>
                Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>)
                are required to be filled.
              </p>
              <el-form-item v-if="content_id !== 7" label="Date Created" prop="date_created">
                <el-date-picker
                  v-model="selected.date_created"
                  type="date"
                  style="width:100%"
                  placeholder="Please Select Date"
                  clearable
                />
              </el-form-item>
              <el-form-item v-if="content_id === 1 || content_id === 2 || content_id === 5" label="Position">
                <select-position
                  v-if="ae_form"
                  :post="selected.position"
                  style="width: 100%"
                  @setPosition="selected.position_id = $event"
                />
              </el-form-item>
              <el-form-item v-if="content_id === 7" label="Memorandum">
                <select-memo
                  v-if="ae_form"
                  :post="selected.memo"
                  style="width: 100%"
                  @setMemo="selected.memo_id = $event"
                />
              </el-form-item>
              <el-form-item
                v-if="ae_form && selected.content_id !== 7"
                ref="path"
                label="Attached File">
                <files201-uploader-component
                  :path.sync="selected.path"
                  class="my-upload-job"
                  @setAttachment="selected.path = $event"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="saveFile()">Save</el-button>
        <el-button type="danger" size="medium" @click="closeFile()">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { Files201Personnel } from '@/api/records/files_201_personnel'
import { ContentList } from '@/api/records/content_list'
import { Files201 } from '@/api/records/files201'
import Files201UploaderComponent from '@/views/layout/components/Records/Files201UploaderComponent.vue'
import SelectPosition from '@/views/layout/components/SelectPosition.vue'
import SelectMemo from '@/views/layout/components/Records/SelectMemo.vue'
import { NotifyError, NotifySuccess } from '@/helper/Helper'

export default {
  name: 'Files201Test',
  components: {
    SelectMemo,
    SelectPosition, Files201UploaderComponent
    //
  },
  props: {
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      search_name: null,
      search_dept_id: null,
      meta: { page: 1 },
      files_201_personnel: [],
      get_all_loading: false,
      get_dept_loading: false,
      departments: [],
      header_name: null,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      personnel_selected: {},
      personnel_id: null,
      view_cl: false,
      search_list: null,
      meta_cl: { page: 1 },
      content_list_count: [],
      get_cl_loading: false,
      content_selected: {},
      content_id: null,
      view_files: false,
      search_year: null,
      search_path: null,
      get_f_loading: false,
      files: [],
      meta_f: { page: 1 },
      naf: false,
      view_attached_file: null,
      view_attached_form: false,
      base_url: this.$store.getters.base_url,
      selected: {},
      ae_title: null,
      ae_form: false,
      append_body: false,
      rules: { date_created: [{ required: true, message: 'Must set date!', trigger: 'blur' }]
      }
    }
  },
  watch: {
    type: function() {
      this.view_cl = false
      this.getType()
      this.search_name = null
      this.search_dept_id = null
      this.personnel_id = null
      this.meta.page = 1
      this.getAll()
      this.getDept()
    },
    search_list: function() {
      if (this.search_list.length === 0) {
        this.meta_cl.page = 1
        this.getCLCount()
      }
    },
    // search_year: function() {
    //   if (this.search_year !== null && this.search_year !== undefined) {
    //     this.search_year = this.$moment(new Date(this.search_year)).format('YYYY')
    //   } else {
    //     this.search_year = null
    //   }
    //   this.meta_f.page = 1
    //   this.getFiles()
    // },
    naf: function() {
      if (this.naf) this.search_path = 1
      else this.search_path = null
      this.meta_f.page = 1
      this.getFiles()
    }
  },
  created() {
    this.getType()
    this.getAll()
    this.getDept()
  },
  methods: {
    getType() {
      if (this.type === 'Faculty') this.header_name = 'Academic'
      else if (this.type === 'Staff') this.header_name = 'Administrative'
    },
    getAll() {
      this.get_all_loading = true
      Files201Personnel(
        { type: this.type, name: this.search_name, unit_id: this.search_dept_id, page: this.meta.page },
        'get'
      ).then(res => {
        // console.log('getAll():', res.data.files_201_personnel)
        this.files_201_personnel = res.data.files_201_personnel
        this.meta = res.data.meta
        this.get_all_loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.get_all_loading = false
      })
    },
    getDept() {
      this.get_dept_loading = true
      Files201Personnel(
        {},
        'get',
        'get_dept'
      ).then(res => {
        // console.log('getDept():', res.data.departments)
        this.departments = res.data.departments
        this.get_dept_loading = false
      }).catch(error => {
        console.log(error)
      })
    },
    search() {
      this.personnel_id = null
      this.view_cl = false
      this.icon_search = 'el-icon-loading'
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.personnel_id = null
      this.view_cl = false
      this.icon_refresh = 'el-icon-loading'
      this.search_name = null
      this.search_dept_id = null
      this.meta.page = 1
      this.getAll()
    },
    viewContentList(obj) {
      // console.log('viewContentList:', obj)
      this.personnel_selected = Object.assign({}, obj)
      this.personnel_id = this.personnel_selected.id
      this.view_cl = true
      this.meta_cl.page = 1
      this.getCLCount()
    },
    closeContentFiles() {
      this.personnel_selected = {}
      this.personnel_id = null
      this.view_cl = false
    },
    getCLCount() {
      this.get_cl_loading = true
      ContentList(
        { personnel_id: this.personnel_id, title: this.search_list, page: this.meta_cl.page },
        'get',
        'content_list_count'
      ).then(res => {
        // console.log('getCLCount:', res.data.content_count)
        // console.log('getCLCount.meta_cl:', res.data.meta)
        this.content_list_count = Object.values(res.data.content_list_count)
        this.meta_cl = res.data.meta
        this.get_cl_loading = false
      }).catch(error => {
        console.log(error)
      })
    },
    searchList() {
      this.meta_cl.page = 1
      this.getCLCount()
    },
    viewFiles(obj) {
      this.content_selected = Object.assign({}, obj)
      this.content_id = this.content_selected.id
      this.view_files = true
      this.meta_f.page = 1
      this.getFiles()
    },
    closeFiles() {
      this.files = []
      this.content_selected = {}
      this.content_id = null
      this.view_files = false
    },
    getFiles() {
      this.get_f_loading = true
      Files201(
        {
          page: this.meta_f.page,
          personnel_id: this.personnel_id,
          content_id: this.content_id,
          year: this.search_year,
          path: this.search_path
        }
      ).then(res => {
        this.files = res.data.files_201
        this.meta_f = res.data.meta
        this.get_f_loading = false
      }).catch(error => {
        console.log(error)
        this.get_f_loading = false
      })
    },
    viewAttachedFile(path, folder) {
      this.view_attached_file = this.base_url + '/records/' + folder + '/download/' + path
      this.view_attached_form = true
    },
    closeAttachedFile() {
      this.view_attached_file = null
      this.view_attached_form = false
    },
    newFile(obj, boolean) {
      // console.log('newFiles(obj, boolean):', obj)
      if (boolean) {
        this.append_body = true
      } else {
        this.append_body = false
        this.content_selected = Object.assign({}, obj)
        this.content_id = this.content_selected.id
      }
      this.ae_title = 'New'
      this.selected = {}
      this.selected.orig_path = null
      this.selected.content_id = this.content_id
      this.selected.personnel_id = this.personnel_id
      this.ae_form = true
    },
    editFile(obj) {
      // console.log('editFile(obj):', obj)
      this.title = 'Edit'
      this.selected = Object.assign({}, obj)
      if (this.selected.add_by_personnel_id) delete this.selected.add_by_personnel
      if (this.selected.updated_by_personnel_id) delete this.selected.update_by_personnel
      // console.log(this.selected)
      this.selected.orig_path = this.selected.path
      this.append_body = true
      this.ae_form = true
    },
    saveFile() {
      this.$refs['selected'].validate((valid) => {
        if (valid) {
          this.selected.date_created = this.getFormattedDate(this.selected.date_created)
          // console.log('saveFile():', this.selected)
          var action = this.selected.id ? 'update' : 'create'
          Files201(this.selected, action).then(res => {
            if (res.data.error === true) {
              NotifyError('Already exists')
            } else {
              NotifySuccess('201 File has been saved successfully')
              if (this.content_id !== 7) this.$refs['selected'].resetFields()
              this.ae_title = null
              this.selected = {}
              this.ae_form = false
              if (this.append_body) {
                this.meta_f.page = 1
                this.getFiles()
              } else {
                this.getAll()
                this.getCLCount()
                this.viewFiles(this.content_selected)
              }
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          return false
        }
      })
      // this.$refs['selected'].validate((valid) => {
      //   //
      // })
    },
    closeFile() {
      if (this.content_id !== 7) this.$refs['selected'].resetFields()
      this.ae_title = null
      this.selected = {}
      this.ae_form = false
    },
    searchFile() {
      if (this.search_year !== null && this.search_year !== undefined) {
        this.search_year = this.$moment(new Date(this.search_year)).format('YYYY')
      } else {
        this.search_year = null
      }
      this.meta_f.page = 1
      this.getFiles()
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    }
  }
}
</script>

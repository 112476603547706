import request from '@/utils/request'
const API = '/api/content_list'

export function ContentList(params = {}, method = 'get', subpath) {
  var url = params.id ? API + '/' + params.id : API
  url = subpath ? url + '/' + subpath : url
  return request({
    url: url,
    method: method,
    params
  })
}

import request from '@/utils/request'
const API = '/api/designation_memo'

export function DesignationMemo(params = {}, method = 'get', subpath) {
  var url = params.id ? API + '/' + params.id : API
  url = subpath ? url + '/' + subpath : url
  if (method === 'post' || method === 'put') {
    return request({
      url: url,
      method: method,
      data: params
    })
  }
  return request({
    url: url,
    method: method,
    params
  })
}

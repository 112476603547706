import { Notification } from 'element-ui'

export function Validate(refName, cb) {
  validate(refName, function(valid) {
    if (valid) {
      cb(true)
    } else {
      Notification.error({
        title: 'Error',
        message: 'Unable to save',
        duration: 4 * 1000
      })
      cb(false)
    }
  })
}

function validate(refName, cb) {
  if (refName) {
    refName.validate((valid) => {
      cb(valid)
    })
  }
}

export function NotifySuccess(message) {
  Notification.success({
    title: 'Success',
    message: message,
    duration: 4 * 1000
  })
}

export function NotifyError(message) {
  Notification.error({
    title: 'Error',
    message: message,
    duration: 4 * 1000
  })
}
